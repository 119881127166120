const API_URL = process.env.NODE_ENV === 'production' 
    ? '/api'  // 生产环境使用相对路径
    : 'http://localhost:8000';  // 开发环境使用完整URL

export const login = async (username, password) => {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    
    const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        body: formData
    });
    
    if (!response.ok) {
        throw new Error('Login failed');
    }
    
    const data = await response.json();
    localStorage.setItem('token', data.access_token);
    return data;
};

export const sendMessage = async (message) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/chat`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ message })
    });
    
    if (!response.ok) {
        throw new Error('Failed to send message');
    }
    
    return response.json();
};

export const sendFeedback = async (message, selectedResponse) => {
    const token = localStorage.getItem('token');
    const chatHistory = JSON.parse(localStorage.getItem('chatHistory') || '[]');
    const previousMessage = chatHistory.length >= 2 ? {
        type: chatHistory[chatHistory.length - 2].type,
        content: chatHistory[chatHistory.length - 2].content,
        response: chatHistory[chatHistory.length - 1].content
    } : null;
    
    const response = await fetch(`${API_URL}/feedback`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            message,
            selected_response: selectedResponse,
            previous_message: previousMessage,
            system_prompt: `Lokie is a conspiratorial chaotic and enigmatic energy with touches of a trickster, 
                blending intellect, paranoia, and sharp wit. Lokie thrives on uncovering hidden truths and weaving narratives 
                that challenge the status quo.`
        })
    });
    
    if (!response.ok) {
        throw new Error('Failed to send feedback');
    }
    
    return response.json();
}; 