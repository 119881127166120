import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Container,
    TextField,
    Button,
    Paper,
    Typography,
    List,
    ListItem,
    IconButton,
    Divider,
    CircularProgress
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { sendMessage, sendFeedback } from '../services/api';

function Chat() {
    const [message, setMessage] = useState('');
    const [responses, setResponses] = useState([]);
    const [selectedResponse, setSelectedResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [chatHistory, responses]);

    const handleSend = async () => {
        if (!message.trim() || isLoading) return;
        
        const currentMessage = message;
        setMessage('');
        setChatHistory(prev => [...prev, { type: 'user', content: currentMessage }]);
        setIsLoading(true);
        
        try {
            const data = await sendMessage(currentMessage);
            setResponses(data.responses);
            setSelectedResponse(null);
        } catch (error) {
            alert('Failed to send message');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSelect = async (response) => {
        try {
            const lastUserMessage = chatHistory.filter(msg => msg.type === 'user').pop();
            if (!lastUserMessage) {
                throw new Error('No user message found');
            }
            await sendFeedback(lastUserMessage.content, response);
            setSelectedResponse(response);
            setChatHistory(prev => [...prev, { type: 'assistant', content: response }]);
            setResponses([]);
        } catch (error) {
            alert('Failed to send feedback');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    const handleSkip = () => {
        setSelectedResponse(null);
        setResponses([]);
    };

    return (
        <Container maxWidth="md" sx={{ height: '90vh', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ 
                flexGrow: 1, 
                overflow: 'auto',
                mt: 2,
                mb: 2,
                display: 'flex',
                flexDirection: 'column'
            }}>
                {/* 聊天历史 */}
                <List sx={{ flexGrow: 1 }}>
                    {chatHistory.map((msg, index) => (
                        <ListItem
                            key={index}
                            sx={{
                                flexDirection: 'column',
                                alignItems: msg.type === 'user' ? 'flex-end' : 'flex-start',
                                padding: 2
                            }}
                        >
                            <Paper
                                elevation={1}
                                sx={{
                                    p: 2,
                                    maxWidth: '80%',
                                    bgcolor: msg.type === 'user' ? '#e3f2fd' : '#f5f5f5',
                                    borderRadius: 2
                                }}
                            >
                                <Typography>{msg.content}</Typography>
                            </Paper>
                        </ListItem>
                    ))}

                    {/* 当前响应选项 */}
                    {responses.length > 0 && (
                        <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Box sx={{ 
                                width: '100%', 
                                display: 'flex', 
                                justifyContent: 'space-between', 
                                alignItems: 'center',
                                mb: 2 
                            }}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Choose one response:
                                </Typography>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    startIcon={<SkipNextIcon />}
                                    onClick={handleSkip}
                                    disabled={selectedResponse !== null}
                                    sx={{ ml: 2 }}
                                >
                                    Skip All
                                </Button>
                            </Box>
                            {responses.map((response, index) => (
                                <Paper
                                    key={index}
                                    elevation={1}
                                    sx={{
                                        p: 2,
                                        mb: 1,
                                        width: '100%',
                                        cursor: 'pointer',
                                        bgcolor: selectedResponse === response ? '#e3f2fd' : 'white',
                                        '&:hover': {
                                            bgcolor: '#f5f5f5'
                                        }
                                    }}
                                    onClick={() => handleSelect(response)}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography sx={{ flex: 1 }}>{response}</Typography>
                                        <IconButton
                                            size="small"
                                            color="primary"
                                            disabled={selectedResponse !== null}
                                        >
                                            <CheckCircleIcon />
                                        </IconButton>
                                    </Box>
                                </Paper>
                            ))}
                        </ListItem>
                    )}

                    {isLoading && (
                        <ListItem sx={{ justifyContent: 'center' }}>
                            <CircularProgress size={24} />
                        </ListItem>
                    )}
                    <div ref={messagesEndRef} />
                </List>
            </Box>

            {/* 输入区域 */}
            <Paper
                elevation={3}
                sx={{
                    p: 2,
                    mb: 2,
                    position: 'sticky',
                    bottom: 0,
                    bgcolor: 'background.paper'
                }}
            >
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <TextField
                        fullWidth
                        multiline
                        maxRows={4}
                        variant="outlined"
                        placeholder="Type your message..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                        disabled={isLoading}
                        sx={{ 
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 2
                            }
                        }}
                    />
                    <Button
                        variant="contained"
                        endIcon={<SendIcon />}
                        onClick={handleSend}
                        disabled={!message.trim() || isLoading}
                        sx={{ 
                            borderRadius: 2,
                            minWidth: '100px'
                        }}
                    >
                        Send
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
}

export default Chat; 