import React, { useState } from 'react';
import { 
    AppBar, 
    Toolbar, 
    Typography, 
    Button, 
    Container, 
    Box 
} from '@mui/material';
import Login from './components/Login';
import Chat from './components/Chat';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(
        !!localStorage.getItem('token')
    );
    
    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
    };
    
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Loki Chat
                    </Typography>
                    {isLoggedIn && (
                        <Button color="inherit" onClick={handleLogout}>
                            Logout
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
            <Container>
                {!isLoggedIn ? (
                    <Login onLogin={() => setIsLoggedIn(true)} />
                ) : (
                    <Chat />
                )}
            </Container>
        </Box>
    );
}

export default App;
